import React from 'react';

const CommonArrowRightBlack = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.125 10H16.875" stroke="#101828" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M11.25 4.375L16.875 10L11.25 15.625"
      stroke="#101828"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CommonArrowRightBlack;
